<template>
  <div class="header" :style="{'background-color':headcolor}">
      <div class="path-guide">
          <i class="iconfont icon-mokuai" style="color:#fff;margin-right:10px"></i>
          <Breadcrumb class="breadcrumb" style="color:white">
              <BreadcrumbItem>后台中心</BreadcrumbItem>
              <BreadcrumbItem v-for="item in $route.matched" :key="item.path"><span style="color:#fff">{{item.meta.title || '未设置路由meta.title'}}</span></BreadcrumbItem>
          </Breadcrumb>
      </div>
      <Drawer :closable="false" width="340" v-model="drawer">
          <div class="pStyle">*** 你最近使用的功能表单 ***</div>
          <div class="flex">
              <span v-for="(item,index) in loveitem" :key="index">
                <span class="line-img">
                    <div style="text-align: center">
                        <img src="@/assets/mylove.png" alt="" width="64" height="64" />
                    </div>
                    <div style="height:10px;"></div>
                    <span style="text-align: center;font-size:1.1em "  >
                       <el-link href="javascript:void(0)" :underline="false" rel="item.FRMURL"  @click.native="loveClick(item.FRMURL,item.FRMNM,item.COMPONENT)"> {{item.FRMNM}}</el-link>
                    </span>
                </span>  
              </span>
          </div>
      </Drawer>
      <div class="option-bar">
          <el-link @click="oaFlow" :underline="false" style="padding-right: 15px; padding-left:10px;color:#fFF;font-size:12px;" >       
             <span style="border-bottom:1px solid #fff">外部协同</span> 
          </el-link>
          <div class="login-user">
              <Avatar icon="md-contact" :style="{background: '#5cadff'}"/>
              <Dropdown style="margin-left:5px" transfer-class-name="login-user-dropdown">
                  <el-link href="javascript:void(0)" style="color:#fff;font-size:12px;" :underline='false'>
                      {{loggedUser.name}}
                      <Icon type="ios-arrow-down"></Icon>
                  </el-link>
                  <DropdownMenu slot="list">
                      <div style="display:flex;justify-content:space-start;width:200px;flex-wrap:wrap;flex-direction: column;" >
                      <DropdownItem  v-for="(item,index) in userSet" :key="index" >
                          <span   @click="logout(item.SUBNO)" style="color:#6495ED">
                          <Icon :type="item.LOGO" style="margin-right:5px;" size="13"></Icon>{{item.CNAME}}
                          </span>
                      </DropdownItem>
 
                      </div>
                  </DropdownMenu>
              </Dropdown>
          </div>
      </div>
      <Modal v-model="userModel" :mask-closable="false" :styles="{left:'10px',top:'10px'}"  scrollable   width="70%"  >
        <div style="max-height:600px;width:100%;overflow:auto">
        <Form ref="userForm" :model="userCol" :label-width="110"   label-position="right" inline  >  
            <el-card style="width:100%;margin-bottom:4px;">
                <div slot="header"  class="card_head">
                    <span style="color:#fFF;font-size:14px;"><i class="el-icon-info" ></i>基础信息</span>   
                    <!--
                    <Avatar style="float: right;margin-right:50px" shape="square" :src="avatar"  />
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" @click="btnUser" type="text" icon="el-icon-upload">上传头像</el-button>                                
                    -->
                </div>  
                <FormItem  :label="item.FLDNM" class="item-width"  v-for="(item,index) in userArr" :key="index">
                    <el-input v-if="item.FLDNO==='PRDCAT'" type="textarea" :rows="2" disabled v-model="userCol[item.FLDNO]"></el-input>
                    <el-input v-else type="text" disabled   v-model="userCol[item.FLDNO]"></el-input>
                </FormItem>                                            
            </el-card>
            <el-card style="width:100%;margin-bottom:4px;">
                <div slot="header"  class="card_head">
                    <span style="color:#409EFF;font-size:14px;"><i class="el-icon-info" ></i>收货信息</span>                     
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" type="text" icon="el-icon-delete" @click="shipDel">删除</el-button>
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" type="text" icon="el-icon-edit" @click="shipEdit">修改</el-button> 
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" type="text" icon="el-icon-plus" @click="shipAdd">新增</el-button>                                                    
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" type="text" icon="el-icon-setting" @click="shipSet">设为默认</el-button> 
                </div> 
                <el-table  :data="rcvData" 
                            border                            
                            disabled-hover
                            style="overflow:auto;margin-top:1px;"
                            size="small"
                            ref="frmtab"
                            height="200" 
                            highlight-current-row
                            @row-click="rowClick"  >
                        <!--el-table-column
                            type="index"
                            fixed
                            width="50">
                        </!el-table-column-->
                        <template v-for="(item ,index) in tabCol">
                        <el-table-column   v-if="item.THIDE==='N'" :key="index"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :width="item.width">
                        </el-table-column>  
                        </template>                   
                </el-table> 
            </el-card>
            <el-card style="width:100%;margin-bottom:4px;">
                <div slot="header"  class="card_head">
                    <span style="color:#409EFF;font-size:14px;"><i class="el-icon-info" ></i>开票信息</span>   
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" type="text" icon="el-icon-delete" @click="invDel">删除</el-button> 
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" type="text" icon="el-icon-edit" @click="invEdit">修改</el-button>
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" type="text" icon="el-icon-plus" @click="invAdd">新增</el-button>
                    <el-button style="float: right; padding: 3px 20px;font-size:14px;" type="text" icon="el-icon-setting" @click="invSet">设为默认</el-button> 
                </div>
                <el-table  :data="invData" 
                            border                            
                            disabled-hover
                            style="overflow:auto;margin-top:1px;"
                            size="small"
                            ref="frmtab"
                            height="200" 
                            highlight-current-row
                            @row-click="invClick"  >                        
                        <el-table-column  v-for="(item,index ) in invCol" :key="index" 
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :width="item.width">
                        </el-table-column>                                           
                </el-table>  
            </el-card>
        </Form>
        </div>
        <div slot="footer" style="height:24px;">
            <Button type="primary" plain   @click="modal_cancel" style="margin-right:25px;">退出</Button>           
        </div>                       
        <div slot="header" style="height:10px;line-height:10px;padding-left:15px;">
            <span style="font-weight:600;font-size:15px;background-color:#67c23a;color:black;">用户设置</span>
        </div>
      </Modal>  
      <Modal v-model="addModel" :mask-closable="false" :closable="false" :styles="{left:'10px',top:'10px'}"  scrollable   width="50%"  >
        <Form ref="shipForm" :model="shipCol" :label-width="110" :rules="ruleValidate"  label-position="right" inline > 
            <span v-for="(item,index) in tabCol" :key="index" >
            <FormItem  :label="item.FLDNM"  class="row-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO">
                <el-input v-if="item.FLDNO==='RCVADDR'" type="textarea" :rows="3"   v-model="shipCol[item.FLDNO]" ></el-input>
                <el-input v-else-if="item.FLDNO==='RCVTEL'" v-enter-number type="text"   v-model="shipCol[item.FLDNO]" >
                    <el-select v-model="shipCol['AREA']" slot="prepend" style="width:160px; " filterable placeholder="请选择">
                       <el-row v-for="(item,index) in areaCol" :key="index" style="width:240px">
                            <el-option  :label="item.AREACODE" :value="item.AREACODE" ></el-option> 
                       </el-row>
                    </el-select> 
                </el-input>
                <el-cascader
                    v-model="shipCol[item.FLDNO]"
                    clearable
                    v-else-if="item.FLDNO==='RCVPLC'"
                    filterable
                    style="width:100%"
                    :options="countryarea">
                </el-cascader>
                <el-input v-else type="text" :disabled="item.DISABLED==='Y'?true:false "  v-model="shipCol[item.FLDNO]" ></el-input>
            </FormItem>
            </span>
        </Form>
        <div slot="footer" style="height:24px;">
            <Button type="primary" plain   @click="addModel=false" style="margin-right:25px;">取消</Button> 
            <Button type="primary" plain   @click="shipCfm" style="margin-right:25px;">确定</Button>            
        </div>
        <div slot="header" style="height:10px;line-height:10px">
            <span style="font-weight:600;font-size:14px;background-color:#67c23a;color:black;">    
                {{shiptype==='create'?'新增记录':'修改记录'}}
            </span>
        </div> 
      </Modal>
      <Modal v-model="invModel" :mask-closable="false" :closable="false" :styles="{left:'10px',top:'10px'}"  scrollable   width="50%"  >
        <Form ref="invForm" :model="invRow" :label-width="110" :rules="invValidate"  label-position="right" inline > 
            <template v-for="(item,index) in invCol"  >
                <FormItem :key="index" :label="item.FLDNM"  class="row-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO">                  
                    <el-radio-group v-model="INVTY"  v-if="item.FLDNO==='INVTY'" size="small" @change="invChange">
                        <el-radio-button label="普通发票"></el-radio-button>
                        <el-radio-button label="增值税专用发票" ></el-radio-button>
                    </el-radio-group>  
                    <el-radio-group v-model="HEADTY"  v-else-if="item.FLDNO==='HEADTY'" size="small">
                        <el-radio-button label="个人" :disabled="radiobool"></el-radio-button>
                        <el-radio-button label="单位"></el-radio-button>
                    </el-radio-group>   
                    <el-input v-else-if="item.FLDNO==='BANKNO'" v-enter-number type="text"  v-model="invRow[item.FLDNO]" ></el-input>    
                    <el-input v-else type="text" :disabled="item.DISABLED==='Y'?true:false "  v-model="invRow[item.FLDNO]"></el-input>
                </FormItem>
            </template>
        </Form>
        <div slot="footer" style="height:24px;">
            <Button type="text"    @click="invModel=false" style="margin-right:25px;">取消</Button> 
            <Button type="primary" plain   @click="invCfm" style="margin-right:25px;">确定</Button>            
        </div>
        <div slot="header" style="height:10px;line-height:10px">
            <span style="font-weight:600;font-size:14px;background-color:#67c23a;color:black;">    
                {{shiptype==='create'?'新增记录':'修改记录'}}
            </span>
        </div> 
      </Modal>
      <Modal v-model="pwdModel" :mask-closable="false" :closable="false" :styles="{left:'10px',top:'10px'}"  scrollable   width="50%"  >
        <Form  v-model="pwdform" :rules="pwdValidate" :label-width="110" ref="pwdform"  label-position="right" inline > 
            <FormItem  label="新密码"  class="row-width" prop="NEWPWD">                  
                <el-input  show-password
                           placeholder="请输入新密码(长度不少于6位)"
                           type="password"
                           v-model="NEWPWD"> 
                    <template slot ="prepend" ><i class="el-icon-lock" style="color:#409EFF"></i></template>
                </el-input>           
            </FormItem>
            <FormItem  label="确认密码"  class="row-width" prop="CFMPWD">                  
                <el-input  show-password
                           placeholder="请确认新密码(长度不少于6位)"
                           type="password"
                           v-model="CFMPWD"> 
                    <template slot ="prepend" ><i class="el-icon-lock" style="color:#409EFF"></i></template>
                </el-input>           
            </FormItem>
        </Form>
        <div slot="footer" style="height:24px;">
            <Button type="text"    @click="pwdModel=false" style="margin-right:25px;">取消</Button> 
            <Button type="primary" plain   @click.native="pwdCfm" style="margin-right:25px;">确定</Button>            
        </div>
        <div slot="header" style="height:10px;line-height:10px">
            <span style="font-weight:600;font-size:14px;color:#ccc">修改登录密码</span>
        </div> 
      </Modal>
      <el-dialog :visible.sync="wxModel" width="520px" append-to-body :close-on-click-modal="false" >     
        <iframe :src="wxsrc" width="500px" height="400px" style="border:none"></iframe>    
        <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
            <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">微信绑定</span>
        </div>  
      </el-dialog>
      <el-dialog :visible.sync="bindModel" width="900px" append-to-body :close-on-click-modal="false" >
        <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
            <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">绑定设置</span>
        </div>  
        <bindInfo ref="refbind" @child-back="bindWX" v-bind:v_idseq="v_idseq" :v_userno="v_username" :v_pwd="v_password"></bindInfo>
 
      </el-dialog>        
  </div>
</template>

<script>
import {getBaseData,getTabColOrData} from '../api/user'; 
//import SkinSet from './SkinSet'
//import Fullscreen from './fullscreen'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import {newTab} from '@/components/Home.vue'
import {usersetArray,getImage} from '../api/Select'
import bindInfo from '@/views/member/bind_info.vue'
export default {
 
  data(){
    return {
      isFullscreen: false,
      radiobool:false,
      wxModel:false ,
      bindModel:false,
      headcolor:'',
      avatar:'assets/basedict/nopic.png',
      countryarea:[], //级联数据源
      rcvData:[] ,// 收货地址数据
      tabCol:[] ,//收货表列集合
      shipCol:{}, //收货行记录
      invData:[] ,// 发票明细数据
      invCol:[] ,//发票列集合
      invRow:{}, //发票行记录
      drawer:false,
      textbool:true,
      flag:true,
      mylove:'我的最爱',
      loveitem:[], //用户最近点击明细
      loggedUser:{
          name: this.$cookies.get('v_usernm'),
          role:'管理员',
      },
      INVTY:'普通发票' ,
      HEADTY:'个人',
      invModel:false,
      pwdModel:false,
      userSet:[],
      areaCol:[],
      userModel :false,
      addModel :false,
      shiptype:'create',
      lstseq:'-1',
      lstseq2:'-1',
      rowData:{},
      iTime:'',
      userCol:{} , //用户设置--基本信息
      userArr:[],
      pwdform:{},
      CFMPWD:'',
      NEWPWD:'',
      ruleValidate: {RCVADDR:[{required:true,trigger:'blur',message:'收货详细地址不能为空'}] ,
                     RCVER:[{required:true,trigger:'blur',message:'收货人不能为空'}],
                     RCVTEL:[{required:true,trigger:'blur',message:'电话号码不能为空'}],
                     //RCVPLC:[{required:true,trigger:'blur',message:'所在地区不能为空'}],
                     },
      pwdValidate: {NEWPWD:[{required:true,trigger:'blur',message:'新密码不能为空'}] ,
                     CFMPWD:[{required:true,trigger:'blur',message:'确认密码不能为空'}],
                     },                     
      v_url:this.$store.state.queryUrl , //api请求路径      
      v_username:this.$cookies.get('v_username'), //api 请求用户
      v_password:this.$cookies.get('v_password') ,//api 请求密码
      v_idseq:this.$cookies.get('idseq'), //api 请求用户
      wxsrc:'https://open.weixin.qq.com/connect/qrconnect?appid=wxad557d574cd2196c&redirect_uri=http://www.ipskre.com/%23/loginwx&response_type=code&scope=snsapi_login&state='+this.$cookies.get('v_username')+'#wechat_redirect',
      telTag:'未绑定',
      mailTag:'未绑定',
      wxTag:'未绑定',
      qqTag:'未绑定',
      testVal:'',
    }
  },
  computed:{
    tabs () {
        return this.$store.state.tabs
    },
    invValidate(){
        if(this.INVTY!=='普通发票'){
            return { TITLE:[{required:true,trigger:'blur',message:'发票抬头不能为空'}] ,
                     TAXNO:[{required:true,trigger:'blur',message:'单位税号不能为空'}],
                     ADDRESS:[{required:true,trigger:'blur',message:'注册地址不能为空'}],
                     TELNO:[{required:true,trigger:'blur',message:'注册电话不能为空'}],
                     BANK:[{required:true,trigger:'blur',message:'开户银行不能为空'}],
                     BANKNO:[{required:true,trigger:'blur',message:'开户银行不能为空'}],
                    }
        }else{
            if (this.HEADTY==='个人'){
                return { TITLE:[{required:true,trigger:'blur',message:'发票抬头不能为空'}] ,
                         TAXNO:[{required:false,trigger:'blur',message:''}],}
            }else{
                return { TITLE:[{required:true,trigger:'blur',message:'发票抬头不能为空'}] ,
                     TAXNO:[{required:true,trigger:'blur',message:'单位税号不能为空'}],
                     ADDRESS:[{required:false,trigger:'blur',message:''}],
                     TELNO:[{required:false,trigger:'blur',message:''}],
                     BANK:[{required:false,trigger:'blur',message:''}],
                     BANKNO:[{required:false,trigger:'blur',message:''}],
                    }
            }
        }
    },
 
  },
  components:{
    //  SkinSet,
     // Fullscreen,
      bindInfo,
  },
 
  mounted() {
      console.log('testval:'+this.testVal)
    //查询该用户当前是否有未读的通知消息列表，如果有显示未读消息徽标
    //如果今天的、未读的消息中，有消息级别比较重要，可以弹出全局通知
    //this.checkNotices();
    //同时准备接收系统推送
    //this.enableBroadcastReceiver();
    // 设置初始语言
    //this.setLocal(this.$i18n.locale);
    // 用户设置的 皮肤
    getBaseData(this.$store.state.nologinUrl, 'idseq',this.$cookies.get('idseq'),'','','v_sys_user','').then(res=>{
       if (res.data.reslut)  {
         this.headcolor=res.data.result[0].HEADBG
         //this.avatar=res.data.result[0].ICONPATH
       }
    })
    // 用户最近访问表单
    getBaseData(this.$store.state.nologinUrl, 'userno',this.$cookies.get('v_username'),'','','v_userlog','').then(res=>{
        this.loveitem=res.data.result
    })
    //用户菜单设置
    usersetArray().then((res)=>{
        this.userSet =res.data.result
    }) 
    this.getUserInfo(),
    //收货地址表字段
    getBaseData(this.$store.state.nologinUrl, 'idseq','24314','','','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
        this.tabCol=res.data.result
    })
    //发票表字段
    getBaseData(this.$store.state.nologinUrl, 'idseq','24313','','','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
        this.invCol=res.data.result
    })
    this.getShipData() //收货地址
    this.getInvData() //发票明细
    //国家代号
    getBaseData(this.$store.state.nologinUrl, '','','','','v_sys_base'," and parentno in (select code from sys_base where parentno='238')").then(res=>{
        this.areaCol=res.data.result
    })
   // this.shipCol['AREA'] ='305' //中国(+86)
    // 国家 级联数据
    getBaseData(this.$store.state.nologinCasUrl, 'parentno','238','','','v_sys_base','').then(res=>{
        this.countryarea=res.data
    })
    //用户基本信息字段
    getBaseData(this.$store.state.nologinUrl, 'idseq','24319','','','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
        this.userArr=res.data.result
    })
  },
  methods:{
      oaFlow(){
          this.$router.push({
            path: '/sysmgt/scm_chart',
          }, () => {})
    },
    ...mapMutations([
      'setLocal',
    ]),
    bindWX(){
        this.wxModel =true
    },
    //用户信息
    getUserInfo(){
        getBaseData(this.$store.state.nologinUrl, 'empno',this.$cookies.get('v_username'),'','','v_hr_emp','').then(res=>{
            if (res.data.result.length>0) {
                this.userCol=res.data.result[0]
            }
        })
    },
    //密码修改
    pwdCfm(){  //确定新增
        let vm=this     
        let pwd_ =this.NEWPWD
        let cfmpwd_=this.CFMPWD
        if (!pwd_){
            this.$Message.error('新密码不能为空')
        }else if (!cfmpwd_){
            this.$Message.error('确认密码不能为空')
        }else{
            if(pwd_.length<6){
                this.$Message.error('密码长度不能少于6位')
            }else if(cfmpwd_.length<6){
                this.$Message.error('确认密码长度不能少于6位')
            }else if(pwd_!==cfmpwd_){
                this.$Message.error('新密码和确认密码不匹配,请核查')
            }else{
                vm.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/updatePassword?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_value:vm.$cookies.get('v_username'), p_table:'sys_user',p_value2:pwd_},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        vm.pwdModel =false   
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                })     
            }         
        }
    },
    //发票明细 操作
    invClick(row, index) {
        this.lstseq2=row.LSTSEQ
        this.invRow=row
    },
    invChange(){
        if (this.INVTY==='增值税专用发票'){
            this.radiobool=true
            this.HEADTY='单位'
        }else{
            this.radiobool=false
        }
    },
    invSet(){
        if (this.lstseq2==='-1'){
            this.$Message.error('请选择一条记录')
        }else{
            var vm=this;
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                data: {p_key:'idseq',p_value:this.userCol.IDSEQ,p_table:'HR_EMPINV',p_cond:' set ISDEF=case when lstseq='+this.lstseq2+' then 1 else 0 end'},
            }).then(res=>{      
                if(res.data.code =='200')  {      
                    vm.getInvData()
                }else{
                    this.$Message.error(res.data.message)
                }        
            }) 
        }
    },
    invAdd(){
        this.invModel=true
        this.invRow={}
    },
    invEdit(){
        if (this.lstseq2==='-1'){
            this.$Message.error('请选择一条记录')
        }else{
            this.invModel=true
        }
    },
    invDel(){
        if (this.lstseq2==='-1'){
            this.$Message.error('请选择一条记录')
        }else{
            this.$Modal.confirm({
                title: '删除确认',
                content: '<p>你确定要删除此行记录吗</p>',
                onOk: () => {
                    //发起删除请求
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                        data: {p_key:'idseq',p_value:this.userCol.IDSEQ,p_key2:'lstseq',p_value2:this.lstseq2,p_table:'HR_EMPINV'},
                    }).then(res=>{      
                        if(res.data.code =='200')  {      
                            vm.getInvData()
                            vm.lstseq2==='-1'     
                        }else{
                            this.$Message.error(res.data.message)
                        }        
                    }) 
                }
            });
        }
    },
    invCfm(){  //确定新增
        let vm=this
        let obj_=Object.assign(this.invRow,{'INVTY':this.INVTY,'HEADTY':this.HEADTY,'IDSEQ': this.userCol.IDSEQ}) 
        this.$refs['invForm'].validate((valid)=>{
            if(valid){
                vm.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(obj_), p_table:'HR_EMPINV',p_idseq:this.userCol.IDSEQ},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        vm.invModel =false   
                        vm.getInvData()
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                })              
            }
        })
    },
    //收货地址 操作
    rowClick(row, index) {
        this.lstseq=row.LSTSEQ
        this.shipCol=row
    },
    shipAdd(){
        this.addModel=true
        this.shipCol={AREA:'中国(+86)'}
    },
    shipEdit(){
        if (this.lstseq==='-1'){
            this.$Message.error('请选择一条记录')
        }else{
            this.addModel=true
        }
    },
    shipSet(){
        if (this.lstseq==='-1'){
            this.$Message.error('请选择一条记录')
        }else{
            var vm=this;
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                data: {p_key:'idseq',p_value:this.userCol.IDSEQ,p_table:'HR_EMPSHIP',p_cond:' set RCVDEFAULT=case when lstseq='+this.lstseq+' then 1 else 0 end'},
            }).then(res=>{      
                if(res.data.code =='200')  {      
                    vm.getShipData()
 
                }else{
                    this.$Message.error(res.data.message)
                }        
            }) 
        }
    },
    shipDel(){
        if (this.lstseq==='-1'){
            this.$Message.error('请选择一条记录')
        }else{
            this.$Modal.confirm({
                title: '删除确认',
                content: '<p>你确定要删除此行记录吗</p>',
                onOk: () => {
                    //发起删除请求
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                        data: {p_key:'idseq',p_value:this.userCol.IDSEQ,p_key2:'lstseq',p_value2:this.lstseq,p_table:'HR_EMPSHIP'},
                    }).then(res=>{      
                        if(res.data.code =='200')  {      
                            vm.getShipData()
                            vm.lstseq==='-1'     
                        }else{
                            this.$Message.error(res.data.message)
                        }        
                    }) 
                }
            });
        }
    },
    shipCfm(){  //确定新增
        let rcvplc =''
        let vm=this
        this.$refs['shipForm'].validate((valid)=>{
            if (valid){
                if (typeof(this.shipCol['RCVPLC'])!=='undefined' ){
                    rcvplc =vm.shipCol['RCVPLC'].join(',') 
                }
                let obj_=Object.assign(this.shipCol,{'RCVPLC':rcvplc,'IDSEQ': this.userCol.IDSEQ}) 
                vm.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(obj_), p_table:'HR_EMPSHIP',p_idseq:this.userCol.IDSEQ},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        vm.addModel =false   
                        vm.getShipData()
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                }) 
            }
        })
    },
    //邮箱验证
    mailBlur(){
        var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
        if (!verify.test(this.userCol.mailaddr)) {
            this.$Message.error('邮箱格式错误, 请重新输入')
            this.$refs.userForm.$el.children[0].focus()
        }
    },
    //修改基本信息
    btnUser(){
        this.textbool =false
    },
    baseSave(){
        if (!this.userCol.EMPNM){
            this.$Message.error('用户姓名不能为空')
        }else {
            var vm =this
            this.$axios({
                method: 'post',
                url: vm.$store.state.baseUrl+'auth/login/formOneSave' ,
                data: {p_json:JSON.stringify(vm.userCol), p_table:'hr_emp'},
            }).then(res=>{      
                if(res.data.result ==='OK')  {          
                    vm.textbool =true 
                }else{
                    vm.$Message.error(res.data.result)
                }        
            }) 
        }
    },
    modal_cancel(){
        this.userModel =false
        this.textbool =true
    },
    //我的最爱 表单点击事件
    loveClick(path,title,component){  //,query:this.$route.query,params:this.$route.params
        this.drawer =false
        if(this.tabs.filter(item=>item.path===path).length){//如果当前路由在tab列表中
            //不创建新的tab 但要将参数覆盖，因为路由参数可能已变化
            this.$store.commit('updateTab',{componentName:component,title:title,path:path,keepAlive:'true' });
            //return;
        } else {
            this.$store.commit('newTab',{componentName:component,title:title,path:path,keepAlive:'true'});
        }
    },
    // 头部css变化
    setSkin(color){
        this.headcolor =color.headbg
    },
    toggleLeftLayout(){
        this.flag =!this.flag
        this.$store.commit('toggleLeftLayout');
    },
    logout(data){
        if (data==='logout'){
            this.$confirm('你确定要退出吗吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                //清除登录状态
                this.$cookies.remove('admin_token');
                //回到登录页
                this.$router.replace('/'); 
            }).catch(()=>{})
        }else if (data==='securitySet'){  //安全设置
            this.userModel =true
        } else if (data==='modifyPwd'){  //密码设置
            this.pwdModel =true
            this.pwdform={}
        }
        else{
            this.$Message.info('编写功能页面进行中....')
        }
    },
    //收货地址表
    getShipData(){
        let v_cond=' and (empno=\''+this.v_username+'\' or telno=\''+this.v_username+'\' or mailaddr=\''+this.v_username+'\')'
        getTabColOrData(this.v_url,this.v_username,this.v_password,'V_HR_EMPSHIP','','','','','','',v_cond).then((res) => {
           this.rcvData=[]
           if (res.data){
                for (var k=0; k<res.data.length; k++){
                    let obj_={}
                    let row_=res.data[k]
                    let rcvplc_=''
                    if (row_.RCVPLC){
                        rcvplc_ =row_.RCVPLC.split(',')
                    }
                    Object.assign(obj_,res.data[k],{'RCVPLC':rcvplc_})
                    this.rcvData.push(obj_)
                } 
           }
        })
    },
    //发票明细表
    getInvData(){
        let v_cond=' and (empno=\''+this.v_username+'\' or etelno=\''+this.v_username+'\' or mailaddr=\''+this.v_username+'\')'
        getTabColOrData(this.v_url,this.v_username,this.v_password,'V_HR_EMPINV','','','','','','',v_cond).then((res) => {
           this.invData=res.data
        })
    },
    //检查通知
    checkNotices(){
    },
    //启用广播接收者（系统主动推送）
    enableBroadcastReceiver(){
        const that=this;
        //模拟websocket收到一条推送消息，先将header栏通知图标增加徽记
        //如果消息级别比较重要，开启全局通知
        setTimeout(()=>{
            that.notice('重要通知','这是一条测试消息');
        },10000);
    },
    //重要的全局通知，需要用户手动点击关闭
    notice(title,content){
        this.$Notice.open({
            title: title,
            desc: content,
            duration: 0
        });
    }
  }
}
</script>
<style lang="less">
    .option-bar .login-user .login-user-dropdown{
        top:46px !important;
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 5px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 18px; background:white; top:10px !important; }
    .el-dialog__headerbtn i { background:white;font-size: 18px;  }
    .messageBox .el-message-box__content{
        border-top: 0px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 25px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .header{
    position: absolute;
    left:0;
    right:0;
    top:0;
    z-index: 30;
    padding: 0 20px 0px 5px;
    align-items: center;
    width:100%;
    height: 40px;
    background-color: #0085C8;
    color:white;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    display: flex;
    justify-content: space-between;
    .path-guide{
        display: flex;
        align-items: center;
        .icon-menu{margin-right: 20px;cursor: pointer}
        .icon-menu:hover{background-color:#f5f7f9 }
        @media only screen and (min-width: 320px) and (max-width: 767px) {
            .breadcrumb{display: none}
        }
    }
    .option-bar{
        display: flex;
        align-items: center;
        .login-user{
            display: flex;
            align-items: center;
            font-size:12px;
            font-weight:500;
            padding: 0 0 0 20px;
        }
    }
  }
  .pStyle{
    font-size: 16px;
    color: rgba(139,0,139,0.85);
    line-height: 48px;
    display: block;
    font-weight: 600;
    text-align:center ;
    margin-bottom: 16px;
  }
  .flex{
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;
  }

  .line-img{
    margin-right:4px;
    width:130px;
    height:130px;
    text-align:center;
    vertical-align:middle;
    display:inline-block;
}
 
a:visited{
	color:dark-green;
}
a:hover{
	color:#f04142;
}
a:active{
	color:dark-red;
}
.item-width  {
     width: 48%;
     color:#409EFF;
  }
  .row-width  {
     width: 97%;
  }
 
</style>
