<template>
    <div  >
        <Breadcrumb class="breadcrumb" v-if="hidden">
            <BreadcrumbItem>{{fldObj['mb_mem']}}</BreadcrumbItem>
            <BreadcrumbItem>{{fldObj['mb_bind']}}</BreadcrumbItem>
        </Breadcrumb>
        <div style="border-bottom:1px solid #ccc;margin-top:3px;margin-bottom:10px;"></div>
        <div style="margin-top:15px;margin-bottom:25px;  ">
            <div style="display:flex;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;">
                <div style="margin-left:10px;color:#303133; font-size:14px;width:900px;">
                    {{fldObj['mb_wxbind']}} <span style="margin-left:30px;color:#ed4014">{{wxStfg}}</span>
                </div>
                <div style="float:right;display:flex;width:200px;">
                    <el-link :disabled="wxStfg=='unbind'" type="primary" :underline="false" style="width:150px; font-size:14px;color:#ed4014 " @click="wxUnbind">{{fldObj['mb_un']}}</el-link>
                    <Divider type="vertical" style="background:#409eff;width:2px;margin-top:15px;"></Divider>
                    <el-link :disabled="wxStfg!=='unbind'" type="primary" :underline="false" style="width:150px; font-size:14px; " @click="wxBind">{{fldObj['mb_now']}}</el-link>
                </div>
            </div>
            <div style="display:flex;background:#F2F6FC;line-height:80px;height:80px; ">
                <div style="font-weight:600;font-size:16px;color:#409EFF;margin-left:10px; "> 
                    <i class="iconfont icon-weixin" style="color:#409EFF;font-size:44px;margin-left:20px;"></i>
                    <span v-if="wxStfg!=='unbind'"> 
                        <i class="iconfont icon-chenggong1" style="color:#67C23A;font-size:36px;margin-left:20px;"></i>
                    </span>
                    <span v-if="wxStfg==='unbind'"> 
                        <i class="iconfont icon-cuowukongxin" style="color:#67C23A;font-size:36px;margin-left:20px;"></i>
                    </span> 
                </div>
                <div style="font-weight:600;font-size:16px;color:#67C23A;text-align:right;width:880px;margin-right:10px">
                    <span v-if="v_lang=='CN'"> 
                        <i class="web-font" :style="{'font-size':'22px','color':'#409eff'}">{{fldObj['mb_wx']}}</i>
                    </span>
                    <span v-else> 
                        <i class="web-font" :style="{'font-size':'16px','color':'#409eff'}">{{fldObj['mb_wx']}}</i>
                    </span>
                </div>
            </div>
        </div>
        <!-- 绑定支付宝-->
        <div style="margin-top:15px;margin-bottom:25px;  ">
            <div style="display:flex;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;">
                <div style="margin-left:10px;color:#303133; font-size:14px;width:900px;">
                    {{fldObj['mb_alipaybind']}} <span style="margin-left:30px;color:#ed4014">{{aliStfg}}</span>
                </div>
                <div style="float:right;display:flex;width:200px;">
                    <el-link :disabled="aliStfg=='unbind'" type="primary" :underline="false" style="width:150px; font-size:14px;color:#ed4014" @click="aliUnbind">{{fldObj['mb_un']}}</el-link>
                    <Divider type="vertical" style="background:#409eff;width:2px;margin-top:15px;"></Divider>
                    <el-link :disabled="aliStfg!=='unbind'" type="primary" :underline="false" style="width:150px; font-size:14px; " @click="aliBind">{{fldObj['mb_now']}}</el-link>
                </div>
            </div>
            <div style="display:flex;background:#F2F6FC;line-height:80px;height:80px; "> 
                <div style="font-weight:600;font-size:16px;color:#409EFF;margin-left:10px; "> 
                    <i class="iconfont icon-zhifubaozhifu" style="color:#409EFF;font-size:36px;margin-left:20px;"></i>
                    <span v-if="aliStfg!=='unbind'"> 
                        <i class="iconfont icon-chenggong1" style="color:#67C23A;font-size:36px;margin-left:20px;"></i>
                    </span>
                    <span v-if="aliStfg==='unbind'"> 
                        <i class="iconfont icon-cuowukongxin" style="color:#67C23A;font-size:36px;margin-left:20px;"></i>
                    </span> 
                </div>
                <div style="font-weight:600;font-size:16px;color:#67C23A;text-align:right;width:900px;margin-right:10px">
                    <span v-if="v_lang=='CN'"> 
                        <i class="web-font" :style="{'font-size':'22px','color':'#409eff'}">{{fldObj['mb_alipay']}}</i>
                    </span>
                    <span v-else> 
                        <i class="web-font" :style="{'font-size':'16px','color':'#409eff'}">{{fldObj['mb_alipay']}}</i>
                    </span>
                </div>
            </div>
        </div>
        <!-- 绑定邮箱 -->
        <div style="margin-top:15px;margin-bottom:25px;  ">
            <div style="display:flex;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;">
                <div style="margin-left:10px;color:#303133; font-size:14px;width:900px;">
                    {{fldObj['mb_mailbind']}} <span style="margin-left:30px;color:#ed4014">{{mailStfg}}</span>
                </div>
                <div style="float:right;display:flex;width:200px;">
                    <el-link :disabled="mailStfg=='unbind'" type="primary" :underline="false" style="width:150px; font-size:14px;color:#ed4014" @click="mailUnbind">{{fldObj['mb_un']}} </el-link>
                    <Divider type="vertical" style="background:#409eff;width:2px;margin-top:15px;"></Divider>
                    <el-link :disabled="mailStfg!=='unbind'" type="primary" :underline="false" style="width:150px; font-size:14px; " @click="mailConn">{{fldObj['mb_now']}} </el-link>
                </div>
            </div>
            <div style="display:flex;background:#F2F6FC;line-height:80px;height:80px; "> 
                <div style="font-weight:600;font-size:16px;color:#409EFF;margin-left:10px; "> 
                    <i class="iconfont icon-duanxin" style="color:#409EFF;font-size:36px;margin-left:20px;"></i>
                    <span v-if="mailStfg!=='unbind'"> 
                        <i class="iconfont icon-chenggong1" style="color:#67C23A;font-size:36px;margin-left:20px;"></i>
                    </span>
                    <span v-if="mailStfg==='unbind'"> 
                        <i class="iconfont icon-cuowukongxin" style="color:#67C23A;font-size:36px;margin-left:20px;"></i>
                    </span> 
                </div>
                <div style="font-weight:600;font-size:16px;color:#67C23A;text-align:right;width:880px;margin-right:10px;">
                    <span v-if="v_lang=='CN'"> 
                        <i class="web-font" :style="{'font-size':'22px','color':'#409eff'}">{{fldObj['mb_mail']}}</i>
                    </span>
                    <span v-else> 
                        <i class="web-font" :style="{'font-size':'16px','color':'#409eff'}">{{fldObj['mb_mail']}}</i>
                    </span>
                </div>
            </div>
        </div>
        <!-- 绑定手机 -->
        <div style="margin-top:15px;margin-bottom:25px;  ">
            <div style="display:flex;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;">
                <div style="margin-left:10px;color:#303133; font-size:14px;width:900px;">
                    {{fldObj['mb_telbind']}} <span style="margin-left:30px;color:#ed4014">{{telStfg}}</span>
                </div>
                <div style="float:right;display:flex;width:200px;">
                    <el-link :disabled="telStfg=='unbind'" type="primary" :underline="false" style="width:150px; font-size:14px;color:#ed4014" @click="telUnbind">{{fldObj['mb_un']}}</el-link>
                    <Divider type="vertical" style="background:#409eff;width:2px;margin-top:15px;"></Divider>
                    <el-link :disabled="telStfg!=='unbind'" type="primary" :underline="false" style="width:150px; font-size:14px; " @click="telConn">{{fldObj['mb_now']}}</el-link>
                </div>
            </div>
            <div style="display:flex;background:#F2F6FC;line-height:80px;height:80px; "> 
                <div style="font-weight:600;font-size:16px;color:#409EFF;margin-left:10px; "> 
                    <i class="iconfont  icon-shouji1" style="color:#409EFF;font-size:36px;margin-left:20px;"></i>
                    <span v-if="telStfg!=='unbind'"> 
                        <i class="iconfont icon-chenggong1" style="color:#67C23A;font-size:36px;margin-left:20px;"></i>
                    </span>
                    <span v-if="telStfg==='unbind'"> 
                        <i class="iconfont icon-cuowukongxin" style="color:#67C23A;font-size:36px;margin-left:20px;"></i>
                    </span> 
                </div>
                <div style="font-weight:600;font-size:16px;color:#67C23A;text-align:right;width:880px;margin-right:10px">
                    <span v-if="v_lang=='CN'"> 
                        <i class="web-font" :style="{'font-size':'22px','color':'#409eff'}">{{fldObj['mb_tel']}}</i>
                    </span>
                    <span v-else> 
                        <i class="web-font" :style="{'font-size':'16px','color':'#409eff'}">{{fldObj['mb_tel']}}</i>
                    </span>
                </div>
            </div>
        </div>
        <div style="margin-top:15px;margin-bottom:25px;  ">
            <div style="display:flex;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;">
                <div style="margin-left:10px;color:#303133; font-size:14px;width:900px;">
                    {{fldObj['mb_auth']}}
                </div>
                <div style="float:right;display:flex;width:200px;">
                    <el-link type="primary" :underline="false" style="width:150px; font-size:14px;color:#ed4014" @click="getToken">{{fldObj['mb_token']}}(Token)</el-link>
                </div>
            </div>
            <div v-html="token" style="font-weight:600;font-size:16px;color:#67C23A;width:880px;line-height:35px"> </div>
        </div>
        <!-- <div style="margin-top:15px;margin-bottom:25px;  ">
            <div style="display:flex;background:#E4E7ED;line-height:40px;height:40px;border-left:2px solid #409EFF;">
                <div style="margin-left:10px;color:#303133; font-size:14px;width:900px;">
                    调用百度地图来获取IP位置
                </div>
                <div style="float:right;display:flex;width:200px;">
                    <el-link type="primary" :underline="false" style="width:150px; font-size:14px;color:#ed4014" @click="baiduLocation">获取位置</el-link>
                </div>
            </div>
            <div v-html="location" style="font-weight:600;font-size:16px;color:#67C23A;width:880px;line-height:35px"> </div>
        </div> -->
        <el-dialog :visible.sync="ptDialog" width="450px" append-to-body :close-on-click-modal="false"  :show-close="false" :style="{'border':'1px solid blue'}">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{fldObj['mb_prompt']}}</span>
            </div>
            <div v-html="comment" style="line-height:35px" > </div>
            <span slot="footer" :style="{'height':'40px' }" >
                <el-button size="small" type="primary" @click="ptDialog = false"> {{fldObj['mb_sure']}}</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" width="450px" append-to-body :close-on-click-modal="false"  :show-close="false" :style="{'border':'1px solid blue'}">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{fldObj['mb_prompt']}}</span>
            </div>
            <div v-html="comment"  style="line-height:35px"> </div>
            <span slot="footer" :style="{'height':'40px' }" >
                <el-button size="small" type="primary" @click="dialogVisible = false"> {{fldObj['mb_cancel']}}</el-button>
                <el-button size="small" type="primary" @click="wxUnbindSure"> {{fldObj['mb_sure']}}</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="mailDialog" width="450px" append-to-body  :close-on-click-modal="false"  :show-close="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{fldObj['mb_prompt']}}</span>
            </div>
            <div v-html="comment"  style="line-height:35px" > </div>
            <span slot="footer" :style="{'height':'40px' }" >
                <el-button size="small" type="primary" @click="mailDialog = false">{{fldObj['mb_cancel']}}</el-button>
                <el-button size="small" type="primary" @click="mailBind">{{fldObj['mb_sure']}}</el-button>
            </span>
        </el-dialog>
        <wxForm ref='refwx'  />
        <aliForm ref='refali'  />
        <el-dialog :visible.sync="mailWin" width="450px" append-to-body :close-on-click-modal="false"  :show-close="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{fldObj['mb_mailbind']}}</span>
            </div>
            <div style="line-height:40px;"> {{fldObj['mb_mailaddr']}}：<el-input v-model="mailaddr"></el-input></div>
            <div style="line-height:40px;"> {{fldObj['mb_verify']}}：
                <el-input v-model="mailcode"><el-button slot="append" @click="sendCode"> {{btntext}}</el-button></el-input>
            </div>
            <span slot="footer" :style="{'height':'40px' }" >
                <el-button size="small" type="primary" @click="mailWin = false">{{fldObj['mb_cancel']}}</el-button>
                <el-button size="small" type="primary" @click="addMail">{{fldObj['mb_sure']}}</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="telWin" width="450px" append-to-body :close-on-click-modal="false"  :show-close="false" >
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{fldObj['mb_telbind']}}</span>
            </div>
            <div style="line-height:40px;">{{fldObj['mb_phone']}}：
                <el-input v-model="telno">
                    <el-select v-model="areano" slot="prepend" style="width:160px; "   filterable >
                        <el-row v-for="item in tabCol" :key='item.key' style="width:240px">
                            <el-col :span="24">
                                <el-option :label="item.AREACODE" :value="item.CODE" ></el-option> 
                            </el-col>
                        </el-row>
                    </el-select> 
                </el-input>
            </div>
            <div style="line-height:40px;">{{fldObj['mb_verify']}}：
                <el-input v-model="telcode"><el-button :disabled="disabled" slot="append" @click="getAuthCode">{{fldObj['mb_send']}}</el-button></el-input>
            </div>
            <span slot="footer" :style="{'height':'40px' }" >
                <el-button size="small" type="primary" @click="telWin = false">{{fldObj['mb_cancel']}}</el-button>
                <el-button size="small" type="primary" @click="addTelno">{{fldObj['mb_sure']}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user'; 
import wxForm from '../wxbind.vue'
import aliForm from '../alibind.vue'
import  {getCurrentCityName } from '@/http/getLocation'
export default {
    props:{
        v_idseq:{
            type:String,
            default:'',
        },
        v_userno:{
            type:String,
            default:'',
        },
        v_pwd:{
            type:String,
            default:'',
        },
        v_lang :{type: String }
    },
    data () {
        return {
            menuList:[],
            token:'',
            location:'',
            mailaddr:'', 
            hidden:true,
            tabCol:[], //Select 数据集
            telWin:false,
            telno:'',
            disabled:false, //{{auth_time}}秒后重新发送
            btntext:'',
            telcode:'',
            mailcode:'',
            mailWin:false,
            ptDialog:false,
            dialogVisible:false,
            mailDialog:false,
            wxDialog:false,
            comment:'',
            auth_time:'',
            fldObj:{},
            areano:'305', //中国(+86)
            cfmVal:'', //确定标志
            wxStfg:'', //绑定微信
            mailStfg:'', //绑定邮箱
            telStfg:'', //绑定手机
            aliStfg:'', //支付宝
            v_url :this.$store.state.baseUrl+'auth/login/getBaseData',
            alisrc:'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002174688229&redirect_uri=http%3A%2F%2Fwww.ipskre.com%2F%23%2Flogin_alipay&scope=auth_user&state='+this.$cookies.get('v_telno') +'-front',
        }
    },
    components: {
        wxForm,  
        aliForm,
    },
    computed: {
         
    },
    mounted () {
       this.getField()
       this.btntext= this.fldObj['mb_verify']
    },
    created () {
        if (!this.v_idseq ){
            this.v_idseq =this.$cookies.get('v_idseq')
            this.v_userno=this.$cookies.get('v_telno')
            this.v_pwd=this.$cookies.get('v_enpwd')
        }
        if (!this.v_idseq){
            let routeUrl = this.$router.resolve({
                path: "/frontLogin",
                query: {lang:this.$store.state.lang,form:'base' },
            });
            window.open(routeUrl.href, '_self');
        }else{
            this.initBind()
            this.initMail()
            this.initPhone()
            this.initAlipay()
            //国家代号
           getBaseData(this.$store.state.nologinUrl, '','','','','v_sys_base'," and parentno in (select code from sys_base where parentno='238')").then(res=>{
                this.tabCol=res.data.result
           })
        }     
    },
    methods: {
        //界面字段语言设置
        getField(){
            let that =this
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq', p_value:'56144',p_table:'V_BS_PAGEDTL'},
            }).then(res=>{ 
                for(let k=0 ;k<res.data.result.length; k++){
                    that.fldObj[res.data.result[k]['SUBNO']] =that.v_lang=='CN'?res.data.result[k]['CNAME']:res.data.result[k]['ENAME']
                }
                that.$forceUpdate()
            })        
        },
 
        // 获取地理定位
        baiduLocation() {
            let _this = this
            navigator.geolocation.getCurrentPosition(function (position) {
                let centerPointer = new BMap.Point(position.coords.longitude, position.coords.latitude)
                let convertor = new BMap.Convertor()
                let pointArr = []
                pointArr.push(centerPointer)
                convertor.translate(pointArr, 1, 5, function (data) {
                    if(data.status==0){
                        let marker = new BMap.Marker(data.points[0])
                        _this.BMap.centerAndZoom(data.points[0],17)
                        _this.BMap.addOverlay(marker)
                        var myGeo = new BMap.Geocoder({extensions_town: true})
                        // 根据坐标得到地址描述
                        myGeo.getLocation(data.points[0], function(result){
                            if (result){
                                _this.location = result.addressComponents.city + result.addressComponents.district + result.addressComponents.town
                            }
                        })
                    }
                })
            }, function(e) {
                _this.location = "获取定位位置信息失败：" + e.message
            }, {
                geocode: true
            })
        },
        getCurrCity(){
            getCurrentCityName().then((city)=>{
                this.location =city
            })
        },
        //获取IP地址
        getIpAddr(){
            this.$axios({
                method: 'get',
                url:this.$store.state.baseUrl+'auth/login/get_Address',
               // params: {username:this.v_userno,password:this.v_pwd},
            }).then(res=>{      
                this.location =res.data   
                //console.log(res.data.code)   
            }) 
        },
        //微信解绑确定
        getToken(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/getWebToken',
                params: {username:this.v_userno,password:this.v_pwd},
            }).then(res=>{      
                this.token =res.data.code  
                //console.log(res.data.code)   
            }) 
            /*var vm=this;
            const token='eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJyaWNoaWUiLCJyb2xlcyI6Im1lbWJlciIsInVzZXJOYW1lIjoicmljaGllIiwiaWF0IjoxNjQ5MDYwMDMzLCJleHAiOjE2NDkyMzI4MzN9.R5_aE2MNXW32kUelyzQ_Ut8469Fv5LE4sUCSfWUykcwuTYLCZW2B1QbGI9HLIfSDp2jxUjbEHHfNz1cL7Ozccg'
            this.$axios.defaults.headers['authorization'] = `Bearer ${token}`;
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'secure/testUser',
                //data: {Headers:{authorization: token}},
            }).then(res=>{      
                if(res.data.code ==='200')  {      
                    this.ptDialog=true
                    this.comment=res.data.result
                }else{
                    this.ptDialog=true
                    this.comment=res.data.result
                }        
            }) */
        },
        sendCode(){
            var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
            if (!verify.test(this.mailaddr )) {
                this.ptDialog =true 
                this.comment = this.fldObj['mb_okmail']
            }else{
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'auth/login/sendMail',
                    data: {p_user:this.v_idseq,mailaddr:this.mailaddr},
                }).then(res=>{      
                    if(res.data.code ==='200')  {      
                        this.ptDialog =true 
                        this.comment =this.fldObj['mb_mailsent']
                    }else{
                        this.ptDialog=true
                        this.comment= !res.data.message?this.fldObj['mb_fail']:res.data.message
                    }        
                }) 
            }
        },
        // 微信绑定
        wxBind(){
            if (this.wxStfg==='unbind'){
                if (this.hidden){
                    this.$nextTick(()=>{
                        this.$refs.refwx.wxDialog=true
                    })  
                }else{
                    this.$emit('child-back')
                }        
            }else{
               this.ptDialog=true
               this.comment= this.fldObj['mb_cfmwx']
            }
            
        },
        //微信解绑
        wxUnbind(){
            if (this.wxStfg==='unbind'){
                this.ptDialog=true
                this.comment= this.fldObj['mb_nowx']
            }else{
                this.dialogVisible=true
                this.comment=this.fldObj['mb_unwx']
            }
        },
        //微信解绑确定
        wxUnbindSure(){
            var vm=this;
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'auth/login/updateRec',
                data: {p_key:'idseq',p_value:vm.v_idseq,p_cond:' set wxopenid=null', p_table:'sys_user'},
            }).then(res=>{      
                if(res.data.code ==='200')  {      
                    vm.wxStfg="unbind"  
                    vm.dialogVisible=false
                }else{
                    this.ptDialog=true
                    this.comment=res.data.message
                }        
            }) 
        },
        //邮箱解绑
        mailUnbind(){
            if (this.mailStfg==='unbind'){
                this.ptDialog=true
                this.comment= this.fldObj['mb_nomail']
            }else{
                this.mailDialog=true
                this.cfmVal='mail'
                this.comment= this.fldObj['mb_unmail']
            }
        },
        //支付宝解绑
        aliUnbind(){
            if (this.aliStfg==='unbind'){
                this.ptDialog=true
                this.comment= this.fldObj['mb_noalipay']
            }else{
                this.mailDialog=true
                this.cfmVal='ali'
                this.comment= this.fldObj['mb_unpay']
            }
        },
        //绑定手机
        addTelno(){
            if (!this.telno){
                this.ptDialog=true
                this.comment= this.fldObj['mb_phoneno'] 
            }else if (!this.telcode){
                this.ptDialog=true
                this.comment= this.fldObj['mb_verno'] 
            }else{
                this.$axios({
                    method:'post', 
                    url: this.$store.state.baseUrl+'auth/login/validMessage' ,
                    data:{  //get这里应为params //请求参数
                        telno :this.telno,
                        code :this.telcode,
                    },
                }).then(res =>{     
                    if(res.data.code !== '200')  {  
                        this.ptDialog=true
                        this.comment=res.data.message     
                    }else{
                        this.$axios({
                            method: 'post',
                            url:this.$store.state.baseUrl+'auth/login/updateRec',
                            data: {p_key:'idseq',p_value:this.v_idseq,p_cond:' set telno=\''+this.telno+'\'', p_table:'sys_user'},
                        }).then(res=>{ 
                            this.telWin =false
                            this.telStfg=this.fldObj['mb_bindtel']+this.telno+')'
                        })  
                    }        
                }) 
            }
        },
        //手机解绑
        telUnbind(){
            if (this.telStfg==='unbind'){
                this.ptDialog=true
                this.comment= this.fldObj['mb_notel']
            }else{
                this.mailDialog=true
                this.cfmVal='telno'
                this.comment= this.fldObj['mb_telx']
            }
        },
        //邮箱解绑确定
        mailBind(){
            var vm=this;
            if (this.cfmVal==='mail') {
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'auth/login/updateRec',
                    data: {p_key:'idseq',p_value:vm.v_idseq,p_cond:' set mailaddr=null', p_table:'sys_user'},
                }).then(res=>{      
                    if(res.data.code ==='200')  {      
                        vm.mailStfg="unbind"  
                        vm.mailDialog=false
                    }else{
                        this.ptDialog=true
                        this.comment=res.data.message
                    }        
                }) 
            }else if (this.cfmVal==='telno') {
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'auth/login/updateRec',
                    data: {p_key:'idseq',p_value:vm.v_idseq,p_cond:' set telno=null', p_table:'SYS_user'},
                }).then(res=>{      
                    if(res.data.code ==='200')  {      
                        vm.telStfg= 'unbind' 
                        vm.mailDialog=false
                    }else{
                        this.ptDialog=true
                        this.comment=res.data.message
                    }        
                }) 
            }else if (this.cfmVal==='ali') {
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'auth/login/updateRec',
                    data: {p_key:'idseq',p_value:vm.v_idseq,p_cond:' set alipayid=null', p_table:'SYS_user'},
                }).then(res=>{      
                    if(res.data.code ==='200')  {      
                        vm.aliStfg="unbind"  
                        vm.mailDialog=false
                    }else{
                        this.ptDialog=true
                        this.comment=res.data.message
                    }        
                }) 
            }
        },
        //绑定邮件
        addMail(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'mailaddr',p_value:this.mailaddr,p_key2:'msgno',p_value2:this.mailcode,p_key3:'userno',p_value3:this.v_idseq,
                    p_cond:' and to_date(adddttm,\'yyyy-mm-dd hh24:mi:ss\')>sysdate-1/48', p_table:'bs_mail'},
            }).then(res=>{      
                if(res.data.result.length ==0)  {  
                    this.ptDialog=true
                    this.comment= this.fldObj['mb_wrong']      
                }else{
                this.$axios({
                        method: 'post',
                        url:this.$store.state.baseUrl+'auth/login/updateRec',
                        data: {p_key:'idseq',p_value:this.v_idseq,p_cond:' set mailaddr=\''+this.mailaddr+'\'', p_table:'sys_user'},
                    }).then(res=>{ 
                        this.mailWin =false
                        this.mailStfg=this.fldObj['mb_mailok']+this.mailaddr+')'
                    })  
                }        
            }) 
        },
        // 邮箱绑定
        mailConn(){
            if (this.mailStfg==='unbind'){
                this.mailWin =true       
            }else{
               this.ptDialog=true
               this.comment=this.fldObj['mb_cfmmail']
            }     
        },
        // 支付宝绑定
        aliBind(){
             if (this.aliStfg==='unbind'){
                window.location.href=this.alisrc    
            } 
        },
        // 手机绑定
        telConn(){
            if (this.telStfg==='unbind'){
                this.telWin =true       
            }else{
               this.ptDialog=true
               this.comment=this.fldObj['mb_cfmtel']
            }     
        },
        //初始化绑定状态
        initBind(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq',p_value:this.v_idseq, p_cond:' and wxopenid is not null', p_table:'v_sys_user'},
            }).then(res=>{      
                if(res.data.result.length ==0)  {  
                    this.wxStfg='unbind'        
                }else{
                    this.wxStfg=this.fldObj['mb_bindid'] +res.data.result[0].WXOPENID+')'
                }        
            }) 
        },
        initAlipay(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq',p_value:this.v_idseq, p_cond:' and alipayid is not null', p_table:'v_sys_user'},
            }).then(res=>{      
                if(res.data.result.length ==0)  {  
                    this.aliStfg='unbind'        
                }else{
                    this.aliStfg=this.fldObj['mb_bindid']+res.data.result[0].ALIPAYID+')'
                }        
            }) 
        },
        initMail(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq',p_value:this.v_idseq, p_cond:' and mailaddr is not null', p_table:'v_sys_user'},
            }).then(res=>{      
                if(res.data.result.length ==0)  {  
                    this.mailStfg='unbind'        
                }else{
                    this.mailStfg=this.fldObj['mb_mailok']+res.data.result[0].MAILADDR+')'
                }        
            }) 
        },
        initPhone(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getBaseData',
                data: {p_key:'idseq',p_value:this.v_idseq, p_cond:' and telno is not null', p_table:'v_sys_user'},
            }).then(res=>{      
                if(res.data.result.length ==0)  {  
                    this.telStfg= 'unbind'       
                }else{
                    this.telStfg=this.fldObj['mb_bindtel']+res.data.result[0].TELNO+')'
                }        
            }) 
        },
        //获取验证码
        getAuthCode() {
            if (!this.areano){
                this.ptDialog=true
                this.comment= this.fldObj['mb_chktel']
            }else if (!this.telno){
                this.ptDialog=true
                this.comment= this.fldObj['mb_intel']
            }else {
                let area_ =this.areano==='305'?'china':'foreign'
                //消息验证
                this.$axios({
                    method:'post', 
                    url: this.$store.state.baseUrl+'auth/login/sendMessage' ,
                    data:{  //get这里应为params //请求参数
                        telno :this.telno,
                        area :area_,
                        areano:this.areano
                    },
                }).then(res =>{
                    if (res.data.code === 'OK'){
                        this.disabled = true;
                        this.auth_time = 60;
                        var auth_timetimer =  setInterval(()=>{
                            this.auth_time--;
                            this.btntext=this.auth_time+ this.fldObj['mb_resend']
                            if(this.auth_time<=0){
                                this.disabled = false;
                                this.btntext= this.fldObj['mb_verify']
                                clearInterval(auth_timetimer);
                            }
                        }, 1000);
                    }else{
                        this.ptDialog=true
                        this.comment=res.data.message
                    }
                }) 

            }
        },
    }
}
</script>
<style lang="less">
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
</style> 
 