<template>
  <div>
    <el-dialog :visible.sync="aliDialog" width="710px" append-to-body :destroy-on-close="true" >
          <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
              <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">支付宝绑定</span>
          </div>
          <iframe :src="alisrc" width="700px" height="500px" style="border:none"></iframe>    
    </el-dialog>
  </div>
</template>

<script>
  import {getDate} from '../api/Select'
export default {
  name: 'alipay_bind',
  created () {
    if (this.$route.query.auth_code && this.$route.query.app_id)
     this.loginByAli(this.$route.query.auth_code, this.$route.query.app_id );
  },
  data () {
    return {
      aliDialog:false,
      alisrc:'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002174688229&redirect_uri=http%3A%2F%2Fwww.ipskre.com%2F%23%2Flogin_alipay&scope=auth_user&state='+this.$cookies.get('v_telno')+'&t='+getDate('year'),
      //alisrc:'https://open.weixin.qq.com/connect/qrconnect?appid=wxad557d574cd2196c&redirect_uri=http://www.ipskre.com/%23/wxbind&response_type=code&scope=snsapi_login&state='+this.$cookies.get('v_telno')+'#wechat_redirect',
    }
  },
  methods: {
      loginByAli(code, id ) {
        let that = this;
        this.$axios({
          method: "get",
          url: this.$store.state.baseUrl + "auth/login/loginAlipay",
          params: {
            //get这里应为params //请求参数
            app_id: appid,
            auth_code: code,
            state:this.$cookies.get('v_telno')
          },
        }).then((res) => {
            // this.wxDialog =false
            // console.log(JSON.stringify(res.data) )
            that.$router.back()
        })
    }
  }
}
</script>
<style>
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
</style> 