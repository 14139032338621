<template>
  <div>
    <Button size="large" type="text" @click="backHome">返回首页</Button>
    <Button size="large" type="text" @click="backPrev">返回上一页({{ second }}s)</Button>
  </div>
</template>

<script>
import './error.less'
export default {
  name: 'backBtnGroup',
  data () {
    return {
      second: 3,
      timer: null,
      LANG :'',
    }
  },
  methods: {
    backHome () {
      this.$router.replace({
        name: this.$config.homeName
      })
    },
    backPrev () {
      let routeUrl = this.$router.resolve({
          path: '/',
          query: {lang:this.LANG},
      });
      window.open(routeUrl.href, '_self'); 
    }
  },
  mounted () {
    if ((navigator.language || navigator.browserLanguage).toLowerCase().includes('zh-')>-1){
        this.LANG='CN'
    }else{
        this.LANG='EN'
    }
    this.timer = setInterval(() => {
      if (this.second === 0) this.backPrev()
      else this.second--
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
