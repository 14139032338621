<template>
  <error-content code="404" desc="界面在开发中......" :src="src"/>
</template>

<script>
import error404 from '@/assets/error-404.svg'
import errorContent from './error-page/error-content.vue'
export default {
  name: 'error_404',
  components: {
    errorContent
  },
  data () {
    return {
      src: error404
    }
  }
}
</script>