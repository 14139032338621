import axios from 'axios'
import store from '../store/index'
//1、员工状态
export const stfgArray =() =>{  
    return axios(store.state.nologinUrl, {
      method:'post',
      data:{p_key:'parentno',p_value:'19728',p_table:'v_sys_base' }
    })
} 
//2、离职类型
export const quitArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19732',p_table:'v_sys_base' }
  })
} 
// 3、国家 级联数据
export const countryArray =() =>{  
  return axios(store.state.nologinCasUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'238',p_table:'v_sys_base' }
  })
} 
export const baseCascade =(value) =>{  
  return axios(store.state.nologinCasUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:value,p_table:'v_sys_base' }
  })
}
export const baseLoop =(value,table) =>{  
  return axios(store.state.nologinCasUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:value,p_table:table }
  })
}  
// 3.2、带参数的级联基础数据
export const paramArray =(value) =>{  
  return axios(store.state.nologinCasUrl, {
    method:'post',
    data:{ p_value: value ,p_table:'V_PRD_CATEGORY' }
  })
} 
// 4、员工类别
export const staffTypeArray =() =>{  
  return axios(store.state.baseUrl+'user/getFormGroup', {
    method:'post',
    data:{p_key:'parentno',p_value:'19504',p_key2:'gpnm',p_value2:'',p_key3:'',p_value3:'',p_table:'V_BASE_GPNM' }
  })
} 
// 5、生成单 据编码---项目名称
export const itmArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'20008',p_table:'v_sys_base' }
  })
} 
// 6、生成单 据编码---日期格式
export const dateArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'20009',p_table:'v_sys_base' }
  })
} 
// 7、用户设置菜单
export const usersetArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'20027',p_table:'v_sys_base' }
  })
} 
// 8、用户职位
export const jobArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19723',p_table:'v_sys_base' }
  })
} 
// 9、在职用户
export const staffArray =(key,value) =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'stfg',p_value:'19729',p_key2:key,p_value2:value,p_table:'V_HR_EMP' }
  })
}
// 10、表单card分组
export const gpnmArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'20204',p_table:'v_sys_base' }
  })
} 
// 11、角色明细
export const roleArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'',p_value:'',p_table:'v_sys_role' }
  })
} 
// 11、登录用户
export const loginArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'STATUS',p_value:'Y',p_table:'v_sys_user' }
  })
} 
// 11、表单集合
export const formArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'',p_value:'',p_table:'v_sys_form' ,p_cond:' and frmurl is not null'}
  })
} 
// 12、学历
export const studyArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19894',p_table:'v_sys_base' }
  })
} 
// 13、与我的关系
export const relateArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19904',p_table:'v_sys_base' }
  })
} 
// 14、证件类型
export const idArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19698',p_table:'v_sys_base' }
  })
} 
// 15、民族类型
export const volkArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19726',p_table:'v_sys_base' }
  })
} 
// 16、尊称
export const whatArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'20224',p_table:'v_sys_base' }
  })
} 
// 17、会员等级
export const emplvlArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'20136',p_table:'v_sys_base' }
  })
} 
// 18、政治面貌
export const politicArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19725',p_table:'v_sys_base' }
  })
} 
// 19、婚否
// export const ismarryArray =() =>{  
//   return axios(store.state.nologinUrl, {
//     method:'post',
//     data:{p_key:'parentno',p_value:'19892',p_table:'v_sys_base' }
//   })
// }   
 
// 20、规格明细
export const specArray =(idseq,lstseq) =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'idseq',p_value:idseq,p_key2:'lstseq',p_value2:lstseq,p_table:'V_PRD_PROPDTL' }
  })
}
export const specOneArray =(idseq,lstseq,itmseq) =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'idseq',p_value:idseq,p_key2:'lstseq',p_value2:lstseq,p_key3:'itmseq',p_value3:itmseq,p_table:'V_PRD_PROPDTL' }
  })
}
 
// 21、产品类别：一级科目
export const lvlArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'lvl',p_value:'1',p_cond:' and subno>0 ',p_table:'V_PRD_CATEGORY' }
  })
}
 
// 23、带参数的基础数据
export const paramBase =(value,table) =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:value,p_table:table }
  })
}
// 24、带参数的数据集
export const paramData =(key,value,table) =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:key,p_value:value,p_table:table }
  })
}
// 24、带参数的数据集
export const paramDataOrder =(key,value,table,cond) =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:key,p_value:value,p_table:table,p_cond:cond }
  })
}
// 24、带参数的数据集
export const paramData2 =(key,value,key2,value2,table) =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:key,p_value:value,p_key2:key2,p_value2:value2,p_table:table }
  })
}
// // 25、健康状况
// export const healthArray =() =>{  
//   return axios(store.state.nologinUrl, {
//     method:'post',
//     data:{p_key:'parentno',p_value:'19893',p_table:'v_sys_base' }
//   })
// }   
// 26、技术职称
export const jobtitleArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19724',p_table:'v_sys_base' }
  })
}   
// 27、外语能力
export const englvlArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19895',p_table:'v_sys_base' }
  })
} 
// 28、语言熟练程度
export const langArray =() =>{  
  return axios(store.state.nologinUrl, {
    method:'post',
    data:{p_key:'parentno',p_value:'19911',p_table:'v_sys_base' }
  })
} 
//-- 此接口的意思是通过get方式请求图片,图片类型是blob
export const getImage=(url)=>{ return axios({ method:'get',url:'/upload'+ url,responseType: 'blob'})};
/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */
const getHandledValue = num => {
  return num < 10 ? '0' + num : num
}
/**
   * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
   */
export const getDate = (startType) => {
  const d = new Date()
  const year = d.getFullYear()
  const month = getHandledValue(d.getMonth() + 1)
  const date = getHandledValue(d.getDate())
  const hours = getHandledValue(d.getHours())
  const minutes = getHandledValue(d.getMinutes())
  const second = getHandledValue(d.getSeconds())
  let resStr = ''
  if (startType === 'year') resStr = year +'-'+ month + '-'+ date  +'-'+ hours +'-'+ minutes +'-'+ second
  else if (startType === 'full') resStr = year +'-'+ month + '-'+ date  +' '+ hours +':'+ minutes +':'+ second
  else if (startType === 'yr') resStr = year+''
  else if (startType === 'month') resStr = year+'-'+month
  else resStr = year + '-' + month + '-' + date
  return resStr
}  