import axios from 'axios'

export const login = ({ userName, password }) => { // 登录用户合法性校验
  userName = userName.trim()
  const data = {
    username: userName,
    password: password
  }
  return axios.request({
    url: '/auth/login',
    data: data,
    method: 'post'
  })
}
// 获取用户菜单明细
export const getUserMenu = (url, username, password) => {
  return axios.request({
    url: url,
    params: {
      username,
      password
    },
    method: 'post'
  })
}

export const logout = (token) => {
  return axios.request({
    url: 'logout',
    method: 'post'
  })
}
 
// 返回加密的字符串
export const getEncodePwd = (password) => {
  return axios.request({
    url: 'auth/encodePwd',
    method: 'post',
    data: {
      password
    }
  })
}
  /**   返回表字段集合或表数据集合：参数1：api请求根目录，参数2：api请求用户，参数3：api请求密码
   *    参数4：api请求视图名，参数5：api请求视图字段名，参数6：api请求视图字段值，
   *    参数7：api请求视图字段名 可为空，参数8：api请求视图字段值 可为空，
   *    参数9：api请求视图字段名 可为空，参数10：api请求视图字段值 可为空，
  **/
  export const getTabColOrData =(url_,username_,password_,table_,key_,val_,key2_,val2_,key3_,val3_,cond_) =>{
    let v_url= url_ //+'sysprivs/getTableRecMany'
    return axios(v_url, {
      method:'post',
      params: {
        password :password_,
        username :username_,
      },
      data:{p_key:key_,p_value:val_,p_key2:key2_,p_value2:val2_,p_key3:key3_,p_value3:val3_,p_table:table_,p_cond:cond_}
    })
	}  

  //('http://192.168.2.251:8090/','richie','dGVzdDAx'
export  const getSubMenu =()=> {
    var arr = {};
    let v_url=  'http://192.168.2.251:8090/sysprivs/getRowToColumn'
    return axios(v_url, {
      method:'post',
      params: {
        password :'dGVzdDAx',
        username :'richie',
      }
    })
    /*.then(res=>{
      arr =JSON.stringify(res.data)
      //console.log('submenu:'+ arr )
    }) */
	}
  // 返回基础资料 数据：如客户资料、供应商资料、数据字典 到 select options
  export  const getBaseData =(v_url,v_key,v_value,v_key2,v_value2,v_table,v_cond)=> {
    return axios(v_url, {
      method:'post',
      data: {
        p_key :v_key,
        p_value:v_value,
        p_key2 :v_key2,
        p_value2:v_value2,
        p_table :v_table,
        p_cond:v_cond,
      }
    })
	}
