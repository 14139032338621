import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import NotFound from '../views/404.vue'

Vue.use(VueRouter)

//路由重复 报错解决办法
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location ).catch(err => err)
// };

const routes = [
  {
    path: '/',
    redirect: { path: '/scmLogin' }
  },
 
  // {
  //   path: '/login',
  //   component: () => import('../views/prdmgt/prd_catlist.vue'),
  //   name: 'prd_catlist',
  //   meta: {
  //     title: '景荣电子|授权电子元器件供应商'
  //   }
  // },
  //支付宝
  {
    path: '/alipay',
    component: () => import('../views/ordmgt/alipay.vue'),
    name: 'alipay',
    meta: {
      title: '景荣|支付宝支付' ,
      content: {
        keywords: '微信支付、支付宝支付、信用卡支付',
        description: ' 景荣平台提供多种支付方式'
      }
    }
  },
  //银行转账
  {
    path: '/bankpay',
    component: () => import('../views/ordmgt/carList3-1.vue'),
    name: 'bankpay',
    meta: {
      title: '景荣|银行转账'
    }
  },
 
  //订单详情
  {
    path: '/ordDetails',
    component: () => import('../views/ordmgt/ord_details.vue'),
    name: 'ordDetails',
    meta: {
      title: '景荣|订单详情'
    }
  },
  //询价单
  {
    path: '/member/prcreq',
    component: () => import('../views/member/ord_prcreq.vue'),
    name: 'ord_prcreq',
    meta: {
      title: '景荣|我的询价单'
    }
  },
  {
    path: '/get_forget',
    component: () => import('../views/base/Forget_Password.vue'),
    name: 'Forget_Password'
  },
  {
    path: '/user_register',
    component: () => import('../views/base/Register.vue'),
    name: 'user_register'
  },
  //后台登录
  {
    path: '/scmLogin',
    component: () => import('../views/Login.vue'),
    name: 'scmLogin',
    meta: {
      title: '景荣电子|供应链管理'
    }
  },

  {
    path: '/dashboard',
    component: Home,
    meta:{title:'景荣电子'},
    redirect: { path: '/dashboard/main' },
    children: [    //凡是使用Home作为一级路由view的二级路由，都默认使用tab页方式打开，keepAlive显示指定为true的tab页，将会被缓存在内存中；除非关闭这个tab页触发销毁
      { path: 'main', component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'), meta:{title:'我的工作台',keepAlive:true} },
    ]
  },
  //基础数据模块
  {
    path: '/base',
    component: Home,
    meta:{title:'基础资料'},
    redirect: { path: '/base/base_dic' },
    children: [
      { path: 'base_dic', component: () => import('../views/base/Base_dic.vue'), meta:{title:'数据定义',keepAlive:true} },
      { path: 'mail_content', component: () => import('../views/base/mail_content.vue'),meta:{title:'邮件内容设定',keepAlive:true} },
      { path: 'mail_set', component: () => import('../views/base/mailset.vue'),meta:{title:'邮箱设定',keepAlive:true} },
      { path: 'sys_unit', component: () => import('../views/base/Register.vue'),meta:{title:'单位转换率',keepAlive:true} },
      { path: 'noteinfo', component: () => import('../views/base/noteinfo.vue'),meta:{title:'留言板信息',keepAlive:true} },
      
    ]
  },
  //系统管理模块
  {
    path: '/sysmgt',
    component: Home,
    meta:{title:'系统管理'},
    redirect: { path: '/sysmgt/sys_mod' },
    children: [
      { path: 'sys_mod', component: () => import('../views/sysmgt/Sys_mod.vue'), meta:{title:'模块管理',keepAlive:true} },
      { path: 'sys_formfld', component: () => import('../views/sysmgt/Sys_formfld.vue'), meta:{title:'表单菜单/列字段管理',keepAlive:true} },
      { path: 'sys_userpriv', component: () => import('../views/sysmgt/Sys_userpriv.vue'), meta:{title:'用户角色/权限管理',keepAlive:true} },
      { path: 'hr_employee', component: () => import('../views/hrmgt/hr_employee.vue'), meta:{title:'用户资料管理',keepAlive:true} },
      { path: 'hr_organize', component: () => import('../views/hrmgt/hr_organize.vue'), meta:{title:'组织架构/部门管理',keepAlive:true} },
      { path: 'scm_chart', component: () => import('../views/sysmgt/scm_chart.vue'), meta:{title:'外协管理',keepAlive:true} },
    ]
  },
  //财务模块
  {
    path: '/finmgt',
    component: Home,
    meta:{title:'财务管理'},
    redirect: { path: '/finmgt/sys_crcy' },
    children: [
      { path: 'sys_crcy', component: () => import('../views/sysmgt/sys_crcy.vue'), meta:{title:'货币换算关系',keepAlive:true} },
    ]
  },
  //人事管理模块
  {
    path: '/hrmgt',
    component: Home,
    meta:{title:'人事管理'},
    redirect: { path: '/hrmgt/hr_organize' },
    children: [
 
    ]
  },
  //订单采购模块
  {
    path: '/pomgt',
    component: Home,
    meta:{title:'订单采购管理'},
    redirect: { path: '/pomgt/clnt_askprc' },
    children: [
      { path: 'clnt_askprc', component: () => import('../views/pomgt/clnt_askprc.vue'), meta:{title:'客户询价单',keepAlive:true} },
      { path: 'clnt_order', component: () => import('../views/pomgt/clnt_order.vue'), meta:{title:'销售订单',keepAlive:true} },         
      { path: 'po_prcmgt', component: () => import('../views/pomgt/po_prcmgt.vue'), meta:{title:'采购对账单',keepAlive:true} },         
    ]
  },
  //仓储模块
  {
    path: '/stkmgt',
    component: Home,
    meta:{title:'采购入库'},
    redirect: { path: '/stkmgt/stk_poin' },
    children: [
      { path: 'stk_poin', component: () => import('../views/stkmgt/stk_poin.vue'), meta:{title:'采购入库单',keepAlive:true} },
      { path: 'clnt_order', component: () => import('../views/pomgt/clnt_order.vue'), meta:{title:'销售订单',keepAlive:true} },         
      { path: 'po_quote', component: () => import('../views/pomgt/po_quote.vue'), meta:{title:'采购报价单',keepAlive:true} }, 
      { path: 'po_ord', component: () => import('../views/pomgt/po_ord.vue'), meta:{title:'采购订单',keepAlive:true} }, 
      { path: 'stk_logqry', component: () => import('../views/stkmgt/stk_logqry.vue'), meta:{title:'库存查询',keepAlive:true} }, 
      { path: 'stk_saleout', component: () => import('../views/stkmgt/stk_saleout.vue'), meta:{title:'销售出库单',keepAlive:true} },
      { path: 'po_rtn', component: () => import('../views/stkmgt/po_return.vue'), meta:{title:'采购退货单',keepAlive:true} },
      { path: 'sale_rtn', component: () => import('../views/stkmgt/sale_rtn.vue'), meta:{title:'销售退货单',keepAlive:true} },
      { path: 'stk_check', component: () => import('../views/stkmgt/stk_check.vue'), meta:{title:'盘点单',keepAlive:true} },
    ]
  },
  //供应商模块
  {
    path: '/supmgt',
    component: Home,
    meta:{title:'供应商管理'},
    redirect: { path: '/supmgt/sys_supinfo' },
    children: [
      { path: 'sys_supinfo', component: () => import('../views/supmgt/sys_supinfo.vue'), meta:{title:'供应商资料',keepAlive:true} },
      { path: 'po_ord', component: () => import('../views/pomgt/po_ord.vue'), meta:{title:'采购订单',keepAlive:true} }, 
      { path: 'po_quote', component: () => import('../views/pomgt/po_quote.vue'), meta:{title:'采购询价单',keepAlive:true} }, 
      { path: 'corp_info', component: () => import('../views/supmgt/corp_info.vue'), meta:{title:'公司资料',keepAlive:true} }, 
      { path: 'prd_data', component: () => import('../views/supmgt/prd_data.vue'), meta:{title:'产品资料',keepAlive:true} }, 
      { path: 'ord_pack', component: () => import('../views/supmgt/ord_pack.vue'), meta:{title:'订单确认',keepAlive:true} }, 
      { path: 'po_chk', component: () => import('../views/supmgt/po_chk.vue'), meta:{title:'对账单',keepAlive:true} }, 
      { path: 'req_quote', component: () => import('../views/supmgt/req_quote.vue'), meta:{title:'报价单',keepAlive:true} }, 
      { path: 'ship_notice', component: () => import('../views/supmgt/ship_notice.vue'), meta:{title:'采购送货单',keepAlive:true} }, 
    ]
  },
  //客户模块
  {
    path: '/cltmgt',
    component: Home,
    meta:{title:'客户管理'},
    redirect: { path: '/cltmgt/sys_clntinfo' },
    children: [
      { path: 'sys_clntinfo', component: () => import('../views/clntmgt/sys_clntinfo.vue'), meta:{title:'客户资料',keepAlive:true} },

    ]
  }, 
  //产品模块
  {
    path: '/prdmgt',
    component: Home,
    meta:{title:'产品管理'},
    redirect: { path: '/prdmgt/prd_catlist' },
    children: [
      { path: 'prd_catlist', component: () => import('../views/prdmgt/prd_catlist.vue'), meta:{title:'产品目录表',keepAlive:true} },
      { path: 'prd_datalib', component: () => import('../views/prdmgt/prd_upload.vue'), meta:{title:'产品资料库',keepAlive:true} },
      { path: 'prc_set', component: () => import('../views/prdmgt/prc_set.vue'), meta:{title:'设置品类价格',keepAlive:true} },
      { path: 'prd_proplib', component: () => import('../views/base/Prd_proplib.vue'), meta:{title:'产品属性库',keepAlive:true} },
      { path: 'prdtype_mgt', component: () => import('../views/base/Prdtype_mgt.vue'),meta:{title:'品类维护',keepAlive:true} },
      { path: 'type_docmgt', component: () => import('../views/docmgt/type_docmgt.vue'),meta:{title:'产品类目的文档管理',keepAlive:true} },
    ]
  }, 
 
  //微信绑定
  {
    path: '/wxbind',
    component: () => import('../views/wxbind.vue'),
    name: 'wxbind',
    meta: {
      title: '景荣|微信绑定'
    }
  }, 
  
  //注册中心
  // {
  //   path: '/register',
  //   component: () => import('../views/member/user_register.vue'),
  //   name: 'registerUser',
  //   meta: {
  //     title: '景荣|账号注册'
  //   }
  // }, 
 
  
  //文档管理
  {
    path: '/docmgt/doc_updown',
    component: () => import('../views/docmgt/doc_updown.vue'),
    name: 'docMgt',
    meta: {
      title: '景荣|资源库'
    }
  }, 
 
  //pdf文件预览
  {
    path: '/fullPreview',
    component: () => import('../views/ordmgt/fullPreview.vue'),
    name: 'fullPreview',
    meta: {
      title: '景荣|预览'
    }
  },
  
  
  //前端登录
  {
    path: '/frontLogin',
    component: () => import('../views/sysmgt/login_Front.vue'),
    name: 'frontLogin',
    meta: {
      title: '景荣|客户登录'
    }
  },

 
  //前端管理模块
  {
    path: '/frontmgt',
    component: Home,
    meta:{title:'前端管理'},
    children: [
      { path: 'helpdoc', component: () => import('../views/front/help_info.vue'), meta:{title:'帮助中心',keepAlive:true} },
      { path: 'hot_product', component: () => import('../views/front/hot_product.vue'), meta:{title:'热销产品',keepAlive:true} },
      { path: 'page_manage', component: () => import('../views/front/page_manage.vue'), meta:{title:'页面维护',keepAlive:true} },
      { path: 'tool_mgt', component: () => import('../views/front/tool_mgt.vue'), meta:{title:'工具应用维护',keepAlive:true} },
      { path: 'seo_set', component: () => import('../views/front/seo_set.vue'), meta:{title:'SEO优化',keepAlive:true} },
      { path: 'lang_set', component: () => import('../views/front/lang_set.vue'), meta:{title:'页面英文设置',keepAlive:true} },
      { path: 'aboutus', component: () => import('../views/front/aboutus.vue'), meta:{title:'信息发布',keepAlive:true} },
    ]
  }, 
   
  
  {
    path: '/loginwx',
    component: () => import('../views/WxLogin.vue'),
    name: 'loginwx',
    meta: {
      title: '景荣|微信登录'
    }
  },
  {
    path: '/login_alipay',
    component: () => import('../views/alipayLogin.vue'),
    name: 'login_alipay',
    meta: {
      title: '景荣|支付宝登录'
    }
  },
 
  {
    path: '/404',
    component: NotFound,
    name: '404'
  },
  {
    path: '*',
    redirect: { path: '/404' }
  }
]

const router = new VueRouter({
  //mode: 'hash',  //建议使用history模式
  mode: 'history', //去掉url中的#
  base: process.env.BASE_URL,
  routes//:[{ path: '*', component: NotFound }]
})
 
export default router
