import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import HomeContentLayout from '@/components/HomeContentLayout.vue'
import axios from 'axios'
import './assets/fonts/iconfont/iconfont.css'  //ICON 图标
import './assets/fonts/webfont/font.css'  // ICON FONT 字体
import './assets/css/global.scss'  // 全局样式
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
//引用百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap ,{ak:'UB2WkTyqGCHBw3RH7dTQcX4YFAMFSGx7'}) 
import './directives.js';
import config from '@/config'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import * as echarts from 'echarts'
import splitPane from 'vue-splitpane'
Vue.component('split-pane', splitPane) //全局注册组件，
// Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.config.productionTip = false
//print function
import Print from 'vue-print-nb'
Vue.use(Print)
//import video player
import VideoPlayer from 'vue-video-player'
require ('vue-video-player/src/custom-theme.css')
require('video.js/dist/video-js.css')
Vue.use(VideoPlayer)
//el-select 分页显示 load more
import elSelect from '@/plugin/ElSelect'
Vue.use(elSelect)
 
Vue.use(VueCookies);
Vue.component("HomeContentLayout", HomeContentLayout);
Vue.use(iView)
//console.log('dd'+process.env.VUE_APP_API_URL)
/**
 * @description 全局注册应用配置,每一个vue组件都是vue的实例，所以组件内的this可以拿到 Vue.prototype上添加的属性和方法
 */
 Vue.prototype.$config = config
 Vue.prototype.$axios =axios
 axios.defaults.headers.post['Content-type']='application/form-data;charset=UTF-8'
axios.defaults.baseURL = 'http://192.168.2.251:8090/'
 
//console.log("当前NODE_ENV："+process.env.NODE_ENV);
//console.log("当前API_URL："+process.env.VUE_APP_API_URL);

/**配置cookies开始**/
Vue.prototype.$cookies.config('16h','/') //默认保存一天时间 根目录下

//ivew message 和 notcie 全局配置
Vue.prototype.$Message.config({
  top: 200,
  duration: 3
});
//全局设定
Vue.prototype.$Notice.config({
  top: 200,
  duration: 3
});
//vue 动态设置页面 title
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)


//挂载
new Vue({
  router,
  store,
  render: h => h(App),
  // mounted () {
  //   document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')
