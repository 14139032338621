<template>
  <div>
    <el-dialog :visible.sync="wxDialog" width="450px" append-to-body :destroy-on-close="true" >
          <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
              <span style="line-height:40px;height:40px;font-size:16px;padding-left:5px;">微信绑定</span>
          </div>
          <iframe :src="wxsrc" width="450px" height="400px" style="border:none"></iframe>    
    </el-dialog>
  </div>
</template>

<script>
 
export default {
  name: 'wx_bind',
  created () {
    if (this.$route.query.state && this.$route.query.code)
     this.loginByWx(this.$route.query.state, this.$route.query.code );
  },
  data () {
    return {
      wxDialog:false,
      wxsrc:'https://open.weixin.qq.com/connect/qrconnect?appid=wxad557d574cd2196c&redirect_uri=http://www.ipskre.com/%23/wxbind&response_type=code&scope=snsapi_login&state='+this.$cookies.get('v_telno')+'#wechat_redirect',
    }
  },
  methods: {
      loginByWx(state, code ) {
        let that = this;
        this.$axios({
          method: "get",
          url: this.$store.state.baseUrl + "auth/login/loginOpenid",
          params: {
            //get这里应为params //请求参数
            state: state,
            code: code
          },
        }).then((res) => {
            // this.wxDialog =false
            // console.log(JSON.stringify(res.data) )
            that.$router.back()
        })
    }
  }
}
</script>
<style>
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
</style> 