<template>
  <div style="overflow-y:auto; overflow-x:hidden;display:flex;flex-direction:column;">
    <!--自加的LOGO-->
    <!-- <div class="Logo-left" style="height:40px;width=63px;background-color: transparent;">    </div> -->
    <img src="@/assets/logo-63px.png" style="padding-top:47px;width=63px;height:18px;">      
   
    <!--左边菜单-->
    <el-menu class="el-menu-vertical" style="padding-top:0px"  router :unique-opened="true" :default-active="$route.path" :default-openeds="[$route.matched[0].path]" :collapse="true" background-color="#f7f8f9" >
      <template v-for="(item,index)  in menuList" >
            <!--左边菜单Icon+名称-->
        <el-submenu  :index="item.frmurl" :key="index" >
          <template slot="title">
            <div style="display:flex;flex-direction:column; line-height:25px;margin-top:8px" >
              <div> <i class="iconfont " :class="item.icon" style="font-size:20px;color:#5cadff"></i> </div>
              <div class="itemName-MenuLeft" style=" font-weight:500;color:#000000fa;z-index: 999;margin-left:-12px;font-size:12px"  >{{item.name}}</div>
            </div>
          </template>
                 <!--原来叫"菜单类"名称-->
          <div style="width:320px;line-height:20px;height:25px;border-bottom:1px solid #ccc;font-weight:700;font-size:14px;color:#5cadff">
              <span style="border-left:5px solid #5cadff;margin-left:4px; height:25px;padding-left:15px">{{item.name}}</span>               
          </div>
          <div v-for="(subitem,subindex)  in item.children" :key="subindex" style=" background:white;color:red">
              <!-- <el-menu-item  :index="subitem.islock==='Y'?'subitem.index':subitem.frmurl" @click.native="itemClick"  >
                <i class="iconfont icon-xiayiye1" style="font-size:10px;" :class="subitem.islock==='Y'?'graycss':'blackcss'" ></i>
                <span  :class="subitem.islock==='Y'?'graycss':'blackcss'">{{subitem.name}}</span>
              </el-menu-item>  -->

              <el-menu-item >
              <a @click="topClick(subitem.frmurl,subitem.islock)"  :underline="false" :disabled="subitem.islock==='Y'">
                  <!--红色小圆点-->
                  <div class="submenu-div" >
                   
                     <i class="iconfont icon-yuandianxiao" style="color:red;font-size:13px;margin-right:3px;line-height:45px;height:45px"></i> 
                     <!--蓝色子菜单-->
                     <div style="line-height:45px;height:45px" >{{subitem.name}} </div>
                  </div>
              </a>
              </el-menu-item>

          </div>
          <!-- <span v-for="subitem  in item.children" :key="subitem.index">
            <el-menu-item  :index="subitem.islock==='Y'?'subitem.index':subitem.frmurl" @click.native="itemClick"  >
              <i class="iconfont icon-xiayiye1" style="font-size:10px;" :class="subitem.islock==='Y'?'graycss':'blackcss'" ></i>
              <span  :class="subitem.islock==='Y'?'graycss':'blackcss'">{{subitem.name}}</span>
            </el-menu-item>
 
          </span> -->
        </el-submenu>
      </template>      
    </el-menu>
    <!-- 提示框 -->
      <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
          <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
              <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
          </div>
          <div style=" margin:15px 10px">{{prompt}}</div>
          <div slot="footer">
              <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
          </div>
      </el-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    isShowingLeftLayout () {
      return this.$store.state.isShowingLeftLayout
    }
  },
  data(){
    return {
      menuList: [],
      LANG:'',
      title:'景荣',
      menuVisible:true,
      promptWin:false,
      prompt:'',
    }
  },
  methods:{
    topClick(val,lock){   
      if (lock =='N'){  
        this.$router.push({
            path: val,
        }, () => {})
        var vm=this
        this.$axios({
            method: 'post',
            url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
            data: {p_json:JSON.stringify({'url':vm.$route.path,'userno':vm.$cookies.get('v_username')}), p_table:'USERLOG'},
        }).then(res=>{     
            console.log(vm.$route.path,vm.$cookies.get('v_username')) 
            if (res.data.code!=='200'){
              this.promptWin=true
              this.prompt =res.data.result
            }
        }) 
      }
    },
    getMenuList(){
          //用户菜单 
        this.$axios({
            method:'get',
            url: this.$store.state.baseUrl+'sysprivs/getUserMenu' ,
            params:{  //get这里应为params //请求参数
                password :this.$cookies.get('v_password'),
                username :this.$cookies.get('v_username')
            },
        }).then(res =>{
          this.menuList =res.data  
        })
    }
  },
  mounted () { 
 
    if (this.$cookies.get('v_username') && this.$cookies.get('v_password')){
      this.getMenuList()
      this.LANG =this.$store.state.lang
    } else{
        let routeUrl = this.$router.resolve({
            path: "/",
            query: { },
        });
        window.open(routeUrl.href, '_self');
    }
  }
}
</script>

<style lang="less"  >
  .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px !important;
            color:rgb(128,128, 128);
            font-size:12px;
        }
        td {
            padding: 2px !important ;
            font-size:11px;
        }   
    }

.el-menu--collapse .el-submenu__title span{
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
  }
  .el-menu--collapse .el-submenu__icon-arrow{
    display: none;
  }
 
 
 .el-menu-vertical:not(.el-menu--collapse) {
  width: 250px;
 }
 
 .el-menu-vertical{
  box-sizing: border-box;
 
}
  .el-menu-item {
    height: 45px !important;
    line-height: 45px !important;
    color:#062a5f;
    background-color: transparent !important;

  }
  //下面是billy加的-不起作用
  .el-menu-item.is-active {
    color:#8a1c1c;
    font-weight:200;

  }
  //下面是billy加的-不起作用
  .itemName-MenuLeft.is-active {
    color:#8a1c1c;
    font-weight: bold;
  }
  // .el-menu-item.is-active {
  //   background: #dce2f1 !important;
  //   font-weight:600;
  //   border-radius: 8px 0px 0px 8px;
  //   i {
  //       color: #2a62ce !important;
  //   }
  // }
  //有可能是有用的。

//billy 
  .el-menu-item:hover {
    background: #68b0fd !important;
    font-weight:800;
    border-radius: 3px 0px 0px 3px;
    color:white;
  }

.el-submenu__title {
    height: 50px;
    line-height: 45px;
    background-color: #f5f7f9 !important;
    i {
        width: 16px;
        height: 16px;
        margin-right: 15px;
        color: #094663 !important;
    }
    i:hover{
      color:#42053e !important;
      //background-color :rgb(144, 170, 241);
    }
  }
  //  billy加的
  .el-submenu__title:active {
    color: #8a1c1c !important;
}
  .el-submenu__title:hover {
    color: #e6a23c !important;
}
   //  billy加的  
  .el-table__body-wrapper {
    &::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #409eff;
    }
  }

  ::-webkit-scrollbar {
        width: 6px;
        height:6px;
    }
 
    ::-webkit-scrollbar-track {
        background-color: #e8eff5;
    }
 
    ::-webkit-scrollbar-thumb {
        background-color: #0085c8;
    }
 
    ::-webkit-scrollbar-thumb:hover {
        background-color: #0085C8;
    }
 
    ::-webkit-scrollbar-thumb:active {
        background-color: #e6a23c;
    } 
  // 纵向滚动条
  // .el-scrollbar__bar.is-vertical .el-scrollbar__thumb {
  //   opacity: 1;
  //   width: 8px; // 纵向滑块的宽度
  //   border-radius: 2px;
  //   background-color: rgba(136, 219, 255, 1);
  //   box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  // }
 
</style>
<style scoped lang="less">
   //左边菜单下的子菜单=如：产品资料库 
  .submenu-div{
      width:200px;
      font-size:14px;
      font-weight:500;
      display:flex;
      vertical-align: middle;
  }
  .submenu-div:hover{
      //background:rgb(199, 239, 247);
      color:#e6a23c;

 
  }
  .graycss{
    color:#c0c4cc;
  }
  .blackcss{
    color:#606266;
  }
  .modFont{
    color:#3399ff ;//#2d8cf0;
    font-weight: 800;
    font-size: 14px;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
 
  ::-webkit-scrollbar-track {
    background-color: #f5f7f9;
  }
 
::-webkit-scrollbar-thumb {
  background-color: rgb(0, 133, 200);
}
 
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 133, 201);
}
 
::-webkit-scrollbar-thumb:active {
  //原来是蓝色
  background-color: #e6a23c;
}
 
   .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn{
      font-size: 20px; 
      background:white;
      top:5px !important;
      }

</style>
