import Vue from 'vue'
import Vuex from 'vuex'
import {
  localRead,
  localSave
} from '@/libs/util'
Vue.use(Vuex)
//import { Message } from 'iview'
export default new Vuex.Store({
  state: {
    isShowingLeftLayout:true,//是否显示侧边栏
    baseUrl:'https://dao.ipskre.com:8090/',  //124.71.107.175
    nologinUrl:'https://dao.ipskre.com:8090/auth/login/getBaseData', //未登录时api请求路径 8090
    nologinCasUrl:'https://dao.ipskre.com:8090/auth/login/getCascadeData', //未登录时api请求路径，递归查询，为了级联组件
    queryUrl:'https://dao.ipskre.com:8090/sysprivs/getTableRecMany',
    manyUrl:'https://dao.ipskre.com:8090/sysprivs/getManyRecord',
    wsUrl:'wss://dao.ipskre.com:8090/websocket/',
    local: localRead('local'),
    skin:localRead('skin'),
    lang:'',
    crcy:'',
    tabs:new Array<any>()//在右侧内容区域应当显示的tab页集合
  },
  mutations: {
    toggleLeftLayout(state){
      state.isShowingLeftLayout=!state.isShowingLeftLayout
    },
    newTab(state,tabObj){
      state.tabs.push(tabObj)
    },
    closeTab(state,path){
      state.tabs=state.tabs.filter(item=>item.path!==path);
    },
    updateTab(state,tabObj){
      state.tabs.map(item=>{
        if(item.path===tabObj.path){
          item.componentName=tabObj.componentName;
          item.title=tabObj.title;
          item.keepAlive=tabObj.keepAlive;
          item.query=tabObj.query;
          item.params=tabObj.params;
        }
        return item;
      });
    },
    closeAllTabs(state){
      state.tabs=[];
    },
    keepAliveTab(state,data){
      state.tabs.map(item=>{if(item.path===data.path){item.keepAlive=data.keepAlive;}return item;});
    },
    setLocal (state, lang) {
      localSave('local', lang)
      state.local = lang
    },
    setSkin (state, skin) {
      localSave('skin', skin)
      state.skin = skin
    },
  },
  actions: {
        // 登录

  },
  modules: {
  }
})
